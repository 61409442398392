@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"), url("./Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-SemiBold"), url("./Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Bold"), url("./Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
