.menu-wrapper {
  background-color: $menuBgColor;
  box-shadow: $menuShadow;
  .sidebar-button {
    color: $rootMenuItemTextColor;
    background: $contentShade400;
  }
  .layout-menu-container {
    .layout-menu {
      > li {
        > a,
        > button {
          color: $rootMenuItemTextColor;

          &:hover {
            background-color: $primaryLighterColor;
          }

          &.active-route {
            background-color: $primaryLightColor;
            position: relative;

            &::before {
              content: "";
              width: 4px;
              height: 12px;
              display: block;
              border-radius: 0px 3px 3px 0px;
              position: absolute;
              left: 0;
              background-color: $rootMenuItemTextColor;
            }
          }
        }

        > ul {
          li {
            a,
            > button {
              color: $rootMenuItemTextColor;
              &:hover {
                background-color: $primaryLighterColor !important;
              }
            }
          }
        }
      }
    }
  }
}

@mixin extrenalSubmenuitem {
  li {
    > a,
    > button {
      color: $submenuItemTextColor;

      &:hover {
        background-color: rgba($primaryColor, 0.2);
        background-color: $submenuItemHoverBgColor;
      }
    }

    &.active-menuitem {
      > a,
      > button {
        color: $primaryColor;
        color: $activeRootMenuItemTextColor;
      }
    }
  }
}

@mixin internalSubmenu {
  .layout-menu-container {
    .layout-menu {
      > li {
        > a,
        > button {
          color: $rootMenuItemTextColor;

          &:hover {
            background-color: $primaryLighterColor;
          }
        }

        > ul {
          li {
            a,
            button {
              &.active-route {
                background-color: $primaryLightColor;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $tabletBreakpoint + 1) {
  .menu-wrapper {
    &.layout-sidebar-active {
      @include internalSubmenu;
    }
  }

  &.layout-static {
    .menu-wrapper {
      @include internalSubmenu;
    }
  }

  &.layout-horizontal {
    .menu-wrapper {
      box-shadow: none;
      .layout-menu-container {
        .layout-menu {
          > li {
            > a,
            > button {
              color: $rootMenuItemTextColor;
            }
            &.active-menuitem {
              > a,
              > button {
                background-color: rgba($primaryColor, 0.2);
                background-color: $slimActiveRootMenuItemBgColor;
                color: $slimActiveRootMenuItemTextColor;

                i {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }

                &:hover {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }
              }
            }
            > ul {
              background-color: $menuBgColor;
              box-shadow: $menuShadow;

              @include extrenalSubmenuitem;

              li {
                > a,
                > button {
                  &.active-route {
                    background-color: rgba($primaryColor, 0.2);
                    background-color: $slimActiveRootMenuItemBgColor;
                    color: $slimActiveRootMenuItemTextColor;

                    i {
                      color: $primaryColor;
                      color: $slimActiveRootMenuItemTextColor;
                    }

                    &:hover {
                      color: $primaryColor;
                      color: $slimActiveRootMenuItemTextColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.layout-slim {
    .menu-wrapper {
      .layout-menu-container {
        .layout-menu {
          .layout-menu-tooltip {
            background-color: $menuTooltipBgColor;

            .layout-menu-tooltip-text {
              color: $menuTooltipTextColor;
            }

            .layout-menu-tooltip-arrow {
              border-right-color: $menuTooltipBgColor;
            }
          }

          > li {
            &.active-menuitem {
              > a,
              > button {
                background-color: rgba($primaryColor, 0.2);
                background-color: $slimActiveRootMenuItemBgColor;
                color: $primaryColor;
                color: $slimActiveRootMenuItemTextColor;

                i {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }

                &:hover {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }
              }
            }

            > a,
            > button {
              &.active-route {
                background-color: rgba($primaryColor, 0.2);
                background-color: $slimActiveRootMenuItemBgColor;
                color: $primaryColor;
                color: $slimActiveRootMenuItemTextColor;

                i {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }

                &:hover {
                  color: $primaryColor;
                  color: $slimActiveRootMenuItemTextColor;
                }
              }
            }

            > ul {
              box-shadow: $menuShadow;
              background-color: $menuBgColor;
              @include extrenalSubmenuitem;
              li {
                > a,
                > button {
                  &.active-route {
                    background-color: rgba($primaryColor, 0.2);
                    background-color: $slimActiveRootMenuItemBgColor;
                    color: $primaryColor;
                    color: $slimActiveRootMenuItemTextColor;

                    i {
                      color: $primaryColor;
                      color: $slimActiveRootMenuItemTextColor;
                    }

                    &:hover {
                      color: $primaryColor;
                      color: $slimActiveRootMenuItemTextColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .menu-wrapper {
    @include internalSubmenu;
  }
}
