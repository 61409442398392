//general
$fontSize:14px !default;
$fontFamily:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$mobileBreakpoint:991px !default;
$borderRadius:24px !default;
$animationDuration:.2s !default;
$animationTimingFunction:cubic-bezier(.05,.74,.2,.99) !default;
$letterSpacing:0.02em !default;
$transitionDuration:.2s !default;
$tabletBreakpoint:991px !default;
$phoneBreakpoint:576px !default;