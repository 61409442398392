@media (min-width: $tabletBreakpoint + 1) {
    .layout-wrapper {
        &.layout-slim {
            .menu-wrapper {
                width:64px;
                overflow: visible;

                .layout-menu-container {

                    padding: 0;

                    .layout-menu {
                        overflow: visible;
                        .layout-menu-tooltip {
                            display:none;
                            padding: 0 .412px;
                            position: absolute;
                            left: 72px;
                            top: 16px;
                            line-height: 1;
                            border-radius: 2px;

                            .layout-menu-tooltip-text {
                               padding: 6px 8px;
                               font-weight: 500;
                               min-width: 75px;
                               white-space: nowrap;
                               text-align: center;
                               @include shadow(0 2px 10px 0 rgba(0, 3, 6, 0.16));
                            }

                            .layout-menu-tooltip-arrow {
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-color: transparent;
                                border-style: solid;
                                top: 50%;
                                left: -4px;
                                margin-top: -5px;
                                border-width: 5px 5px 5px 0;
                            }
                        }

                        > li {
                            position: relative;
                            padding: 10px 12px 10px 14px;

                            > a, > button {
                                margin: 0px;
                                padding: 6px;
                                @include border-radius(12px);
                                @include flex();
                                @include flex-align-center();
                                @include flex-justify-center();
                                @include transition(all $transitionDuration);
                                position: relative;
                                border: none;

                                &:before {
                                    display: none;
                                }

                                span {
                                    display: none;
                                }

                                i {
                                    margin-right: 0;
                                    &.layout-submenu-toggler {
                                      display: none;
                                    }
                                }

                                .menuitem-badge {
                                    display: none;
                                    margin-left: auto;
                                }

                                &:hover + .layout-menu-tooltip {
                                    display: block;
                                }
                            }

                            > ul {
                                position: absolute;
                                top: 0;
                                left: 62px;
                                min-width: 250px;
                                max-height: 450px;
                                padding: 10px 10px 0;
                                overflow: auto;

                                &::after {
                                    content: '';
                                    display: block;
                                    height: 10px;
                                }

                                li {
                                    margin: 0;
                                    padding: 0;
                                    > a, > button {
                                        justify-content:flex-start;
                                        padding: 10px 5px;
                                        > span {
                                            visibility: visible;
                                            display: block;
                                        }
                                        > i {
                                            &.layout-submenu-toggler {
                                                visibility: visible;
                                            }
                                        }
                                        &:hover + .layout-menu-tooltip {
                                            display: none;
                                        }
                                    }
                                    @include nested-submenu-indents(10, 2, 10);
                                }

                            }

                            &.active-menuitem {
                                > a, > button {
                                    &:hover + .layout-menu-tooltip {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .layout-topbar {
                .layout-topbar-wrapper {
                    .layout-topbar-left {
                        .layout-topbar-logo {
                            display: none;
                        }
                    }
                }
            }
            .layout-main {
                padding-left: 64px;
            }
        }
    }
}



