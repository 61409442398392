//colors from figma

$fillAccentColor: #cee105;
$fillLight: #e2f604;
$fillDarkColor: #1e202c;
$shade100: #f7f7f8;
$shade000: #f6f7f7; //surface
$shade600: #515c66; //header background
$shade700: #69707a; //text color
$shade300: #f4e8f8;
$shade400: #d4d6d9;
$shade500: #a2a6ab;
$shade800: #adadad;
$shade900: #d9dbdd;
$shade1000: #293241;
$shade1100: #212121;
$darkBlue: #010b33;
$white: #ffffff;
$purple: #8f65c7;
$red: #e01e5a;

// badge
$lightGreen: #c8e6c9;
$darkGreen: #256029;

//global
$fontFamily: Roboto;
$fontSize: 14px;
$fontWeight: 400;
$fontWeightSemiBold: 500;
$fontWeightBold: 700;
$fontSizeSmall: 12px;

$lineHeightSmall: 19px;
$smallerLindenHeight: 14px;

$fontFamilySecondary: PT Root UI;

$scaleMD: 0.75 !default;

// theme colors

$textColor: $shade700;
$primaryLightColor: #cee105;
$primaryColor: $fillAccentColor;
$primaryDarkColor: #cee105;
$primaryDarkerColor: #cee105;
$primaryTextColor: $fillDarkColor;
$primaryLighterColor: rgba($primaryLightColor, 0.5);

$highlightBg: $primaryColor;
$datePickerhighlightBgTd: #e7e7e7;
$highlightTextColor: $white;
$datePickerhighlightTextColorTd: black;

// sizes

$sizeSmall: 6px;
$sizeMedium: 10px;
$sizeLarge: 20px;

$gap: $sizeMedium;
$borderRadius: $sizeSmall;
$borderRadiusMedium: $sizeMedium;
$largeBorderRadius: $sizeLarge;

//button

$buttonPadding: 14px 20px;
$secondaryButtonBg: $shade900;
$buttonHoverBg: $primaryColor;
$buttonHoverBorderColor: $primaryColor;
$buttonPaddingSm: 7px 14px;
$buttonSecondaryTextColor: $white;
$buttonIconOnlyWidth: 38.5px;
$buttonIconOnlyPadding: 0.5rem 0 !default;
$dangerButtonTextColor: $shade000;
$dangerButtonBg: $red;
$secondaryButtonTextColor: $fillDarkColor;
$buttonPaddingM: 10px;

//input

$inputPadding: 11px 9px;
$inputPaddingMD: 12.6px 9px;
$inputPaddingSX: 6.2px 9px;

$primeIconFontSize: 17.5px;
$inputPlaceholderTextColor: $shade600;
$inputHoverBorderColor: #6a6c74;
$inputTextColor: $fillDarkColor;
$iconPadding: 3.25px;
$inputBorder: 1.2px solid $shade400;

$inputBg: $shade000;
$inputDisplayValueBg: $white;
$inputDisplayValueTextColor: $shade600;

// breadcrumb

$breadcrumbBg: none;
$breadcrumbTextColor: $primaryTextColor;
$breadcrumbBorder: none;
$breadcrumbItemTextColor: $shade500;
$breadcrumbLastItemTextColor: $shade500;
$breadcrumbFirstItemTextColor: $textColor;
$breadcrumbItemFontWeight: $fontWeightSemiBold;
$breadcrumbFontSize: 16px;
$breadcrumbItemIconFontSize: 11.5px;
$breadcrumbPadding: 23px 36px;

// form
$formMargin: $gap;

// card

$cardBodyPadding: $gap;
$cardContentPadding: 0;

// tabmenu-nav

$tabviewNavBg: none;
$tabviewHeaderBg: none;
$tabviewHeaderActiveBg: none;
$tabviewHeaderTextColor: $textColor;
$tabviewHeaderFontWeight: $fontWeight;
$tabviewNavBorder: 2px solid $primaryColor;
$tabviewHeaderPadding: 0 calc(#{$gap} / 2) $gap calc(#{$gap} / 2);
$tabviewHeaderTextActiveColor: $darkBlue;
$tabviewHeaderTextActiveFontWeight: $fontWeightBold;
$tabviewHeaderHoverBg: none;
$tabviewHeaderHoverBorderColor: $primaryColor;
$tabviewHeaderBorder: none;

// table

$tableBodyCellPadding: 14px 14px;
$tableBodyRowTextColor: $textColor;
$tableHeaderCellPadding: 14px 14px;
$tableHeaderCellBg: none;
$tableBodyCellBorder: 1px solid rgba($purple, 0.2);
$tableHeaderCellBorderWidth: 1px 0 1px 0;

//tags

$successTagBg: $lightGreen;
$successTagTextColor: $darkGreen;
$badgeFontSize: $fontSizeSmall;
$badgeLineHeight: $smallerLindenHeight;
$tagPadding: 3px 7px;
$buttonPaddingM: 10px;
