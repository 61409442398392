.p-inputtext {
  font-family: $fontFamily;
  font-size: $inputTextFontSize;
  color: $inputTextColor;
  background: $inputBg;
  padding: $inputPadding;
  border: $inputBorder;
  transition: $formElementTransition;
  appearance: none;
  border-radius: $borderRadius;
  line-height: 1.2em;

  &:enabled:hover {
    border-color: $inputHoverBorderColor;
  }

  &:enabled:focus {
    @include focused-input();
  }

  &.p-invalid.p-component {
    @include invalid-input();
  }

  &.p-inputtext-sm {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }

  &.p-inputtext-lg {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }

  &.p-inputtext-light-bg {
    background-color: $inputDisplayValueBg !important;
    color: $inputDisplayValueTextColor;
  }

  &.p-input-search {
    height: 45px;
  }

  &:disabled.p-input-display-value {
    opacity: 1;
  }
}

.p-inputtext-light-bg {
  .p-inputtext {
    background-color: $inputDisplayValueBg !important;
    color: $inputDisplayValueTextColor;
  }
}

.p-float-label > label {
  left: nth($inputPadding, 2);
  color: $inputPlaceholderTextColor;
  transition-duration: $transitionDuration;
}

.p-float-label > label.p-error {
  color: $errorColor;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: nth($inputPadding, 2);
  color: $inputIconColor;
  margin-top: 0;
}

.p-main-input {
  width: 100%;
  input {
    height: 45px;
    font-size: 16px;
    color: #6a6c74;
    width: 100%;
    padding-left: 28px !important;
  }
  i {
    font-size: 20px;
    padding: 2px;
    left: 5px !important;
    top: 6.5px !important;
    margin-top: 0px !important;
  }
}

.p-input-icon-left > .p-inputtext {
  padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize !important;
}

.p-input-icon-left.p-float-label > label {
  left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: nth($inputPadding, 2);
  color: $inputIconColor;
  padding: $iconPadding;
  margin-top: calc((#{$iconPadding} * -1 - #{$primeIconFontSize}) / 2);
}

.p-input-icon-right > .p-inputtext {
  padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
  width: 100%;
}

@include placeholder {
  color: $inputPlaceholderTextColor;
}

.p-input-filled {
  .p-inputtext {
    background-color: $inputFilledBg;

    &:enabled:hover {
      background-color: $inputFilledHoverBg;
    }

    &:enabled:focus {
      background-color: $inputFilledFocusBg;
    }
  }
}

.p-inputtext-sm {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }
}

.p-inputtext-lg {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}

.p-inputtext-md {
  &.p-inputtext {
    padding: $inputPaddingMD;
  }
}

.p-inputtext-sx {
  &.p-inputtext {
    padding: $inputPaddingSX;
  }
}
