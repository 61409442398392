.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray3);
  border-radius: 20px;

  i {
    font-size: 120px !important;
    color: var(--gray3) !important;
  }

  p {
    font-family: Roboto;
    font-size: 17.5px;
    font-weight: 600;
    line-height: 21px;
    color: var(--gray3);
    margin: 30px 0px 0px 0px;
  }
}
