@import "../../assets/theme/custom/theme-light.scss";

.login-body {
  background-color: $white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-wrapper {
  width: 100%;
  max-width: 247px;
  display: flex;
  flex-direction: column;
  gap: $formMargin;
}

.logo-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.pwd-recovery-text {
  font-family: "PT Root UI";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.025em;
  color: var(--gray);
  margin-top: 40px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--lightBlack);
  }
}

.logout-btn {
  color: $red !important;
}
