.p-password {
  &.p-invalid.p-component > .p-inputtext {
    @include invalid-input();
  }
  &.p-inputtext-light-bg > .p-inputtext {
    background-color: $inputDisplayValueBg !important;
    color: $inputDisplayValueTextColor;
  }
}

.p-password-panel {
  padding: $panelContentPadding;
  background: $panelContentBg;
  color: $panelContentTextColor;
  border: $overlayContentBorder;
  box-shadow: $inputOverlayShadow;
  border-radius: $borderRadius;

  .p-password-meter {
    margin-bottom: $inlineSpacing;
    background: $passwordMeterBg;

    .p-password-strength {
      &.weak {
        background: $passwordWeakBg;
      }

      &.medium {
        background: $passwordMediumBg;
      }

      &.strong {
        background: $passwordStrongBg;
      }
    }
  }
}
