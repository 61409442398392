.accordion-list-item-input {
  width: 100% !important;
  border-radius: 10px !important;
}

.accordion-list-item-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
  margin-left: 10px;

  i {
    font-size: 18px !important;
    color: var(--red) !important;
  }

  &:hover {
    background-color: var(--lightGray) !important;
  }
}

.accordion-list-button {
  height: 38px !important;

  &:hover {
    background-color: var(--afterLightGreen) !important;
    border-color: var(--afterLightGreen) !important;
  }
}

.input-group {
  width: 100%;

  :first-child {
    margin-bottom: 4px;
  }
}

.accordion-list-item-mul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.accordion-list-item-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.accordion-list-item-header-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: var(--colorNeutralBackgroundInverted2);
  white-space: nowrap;
}

.accordion-list-item-header-label {
  padding: 3px 7px;
  font-family: Roboto;
  font-size: 12.01px;
  font-weight: 700;
  line-height: 14.08px;
  color: var(--colorPaletteGreen);
  background-color: var(--colorPaletteGreenBackground);
  border-radius: 4px;
  text-transform: uppercase;
}

.accordion-list-item-header-tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  gap: 10px;
}

.accordion-list-item-header-dropdown {
  height: 30px !important;
  margin: 0px 10px !important;
  border-radius: 10px !important;
  overflow: hidden;

  span {
    padding: 7px 9px !important;
    font-size: 14px !important;
  }
}
