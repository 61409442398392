.layout-rightpanel {
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 62px;
  height: calc(100% - 62px);
  padding: 0;
  width: 418px;
  overflow: auto;
  background-color: $contentShade200;
  transform: translate3d(418px, 0px, 0px);
  @include transition(transform $transitionDuration);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);

  .rightpanel-wrapper {
    padding: 22px 20px 40px;

    .rightpanel-section {
      padding: 16px 0;

      .section-header {
        @include flex();
        @include flex-align-center();
        @include flex-justify-between();
        margin-bottom: 16px;

        > h6 {
          margin: 0;
        }
      }
    }
  }
}

.layout-wrapper {
  &.layout-rightpanel-active {
    .layout-rightpanel {
      transform: translate3d(0px, 0px, 0px);
    }
  }
}

@media (max-width: $phoneBreakpoint) {
  .layout-rightpanel {
    width: 100%;
    transform: translate3d(100%, 0px, 0px);
  }
}
