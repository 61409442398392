@font-face {
  font-family: "PT Root UI";
  src: url("./PTRootUIVF.woff2") format("woff2");
  font-weight: 1;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url("./PTRootUI-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url("./PTRootUI-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url("./PTRootUI-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PT Root UI";
  src: url("./PTRootUI-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
