.p-terminal {
	background: $panelContentBg;
	color: $panelContentTextColor;
	border: $panelContentBorder;
	padding: $panelContentPadding;

	.p-terminal-input {
		font-size: $fontSize;
		font-family: $fontFamily;
	}
}
