.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--colorNeutralBackgroundInverted2);
  margin: 0px 0px 10px 0px;
}

.search-input-wrapper {
  display: flex !important;

  i {
    font-size: 14px !important;
  }
}

.search-input {
  height: 31px !important ;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tag {
  padding: 3px 7px;
  text-transform: uppercase;
  border-radius: 4px;
  margin: 10px 10px 0px 0px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 12.01px;
  font-weight: 700;
  line-height: 14.08px;
}
