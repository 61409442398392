.file-upload {
  .file-upload-content {
    background: none !important;
    padding: 0 !important;
    border: none !important;
  }
  :global(.p-fileupload-row) {
    div {
      padding: 0;
    }
  }
}
