@import "../../../theme/custom/theme-light.scss";

.menu-wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  .sidebar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 34px;
    height: 60px;
    line-height: 24px;
    text-align: center;
    top: 50%;
    right: 27px;
    margin-top: -1.5rem;
    border-radius: 0 30px 30px 0;
    transition: background-color 0.2s;
    overflow: hidden;
    cursor: pointer;
    z-index: 999;
    border: 0px;
    @include transition(transform $transitionDuration);
    :hover {
      background: $contentShade300;
    }
  }

  .sidebar-logo {
    height: 62px;
    @include flex();
    @include flex-justify-start();
    @include flex-align-center();
    padding: 0 21px;
    width: 64px;
    img.logo {
      height: 29px;
      border: 0 none;
      margin-left: 2px;
      object-fit: contain;
    }

    img.logo-with-name {
      display: none;
      object-fit: contain;
      width: auto;
    }
    p {
      margin: -6px 0px 0px 40px;
      color: #000;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: none;
    }
  }

  .layout-menu-container {
    height: calc(100% - 62px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :first-child {
      overflow-y: auto !important;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    ul {
      min-height: 90px !important;
    }

    .layout-menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      max-width: 64px;
      overflow: hidden;
      @include transition(all $transitionDuration);

      > li {
        > a,
        > button {
          position: relative;
          &::before {
            content: "";
            width: 4px;
            height: 12px;
            display: block;
            border-radius: 0px 3px 3px 0px;
            position: absolute;
            left: 0;
          }
        }
      }

      li {
        &.active-menuitem {
          > a,
          > button {
            i.layout-submenu-toggler {
              @include rotate(-180deg);
            }
          }
        }
        svg {
          min-width: 24px;
        }

        .layout-menu-tooltip {
          display: none;
        }

        > a,
        > button {
          margin: 0px;
          @include flex();
          @include flex-align-center();
          font-size: 13px;
          padding: 10px 0px 10px 21px;
          user-select: none;
          cursor: pointer;
          width: 100%;
          > span {
            margin-left: 10px;
            font-weight: $fontWeightSemiBold;
            font-size: 12px;
            line-height: 1.2rem;
            visibility: hidden;
            white-space: nowrap;
          }

          > i {
            font-size: 24px;
            &.layout-submenu-toggler {
              @include transition(transform $transitionDuration);
              margin-right: 20px;
              margin-left: auto;
              font-size: 12px;
              visibility: hidden;
            }
          }

          &.rotated-icon {
            .layout-menuitem-icon {
              transform: rotate(90deg);
            }
          }

          .layout-menuitem-icon {
            font-size: 22px;
            padding: 1px;
          }
        }

        > ul {
          list-style-type: none;
          overflow: hidden;
          padding: 0;
          margin: 0;

          > li {
            padding: 0;

            > a,
            > button {
              margin-left: 0px;
              padding-right: 8px;
            }

            i {
              min-height: 24px;
              min-width: 24px;
              font-size: 14px;
            }

            ul {
              li {
                padding: 0;
              }
            }

            &.layout-root-menuitem {
              > a {
                @include flex();
              }
            }
          }
        }
      }
    }
  }
}

@mixin internalSubmenu {
  .layout-menu {
    max-width: 230px;
    > li {
      min-width: 230px;
    }
    li {
      @include nested-submenu-indents(10, 2, 10);
      > a,
      > button {
        > span {
          visibility: visible;
          white-space: normal;
        }
        i {
          &.layout-submenu-toggler {
            visibility: visible;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) {
  .layout-wrapper {
    &.layout-sidebar {
      .layout-topbar {
        .layout-topbar-wrapper {
          .layout-topbar-left {
            .layout-topbar-logo {
              display: none;
            }
          }
        }
      }
      .layout-main {
        padding-left: 64px;
      }
    }
    &.layout-static {
      .sidebar-button {
        transform: translate(27px) rotateY(180deg);
      }
      .menu-wrapper {
        transform: translate3d(0px, 0px, 0px);
        .sidebar-logo {
          justify-content: space-between;

          img.logo-with-name {
            display: inline;
            height: 29px;
          }
          p {
            display: block;
          }

          .logo {
            display: none;
            color: #8f65c7;
          }

          .app-name {
            display: inline;
          }

          .sidebar-pin {
            display: inline-block;
            > span {
              display: block;
              height: 16px;
              width: 16px;
              @include border-radius(50%);
              border: 2px solid $dividerColor;
              border: 2px solid var(--primary-light-color);
              background-color: $dividerColor;
              background-color: var(--primary-lighter-color);
            }
          }
        }
        @include internalSubmenu;
      }

      .layout-main {
        padding-left: 230px;
        @include transition(padding-left $transitionDuration);
      }
    }
  }

  .menu-wrapper {
    &.layout-sidebar-active {
      transform: translate3d(0px, 0px, 0px);
      .sidebar-logo {
        justify-content: space-between;

        img {
          display: inline;
        }

        .app-name {
          display: inline;
        }

        .sidebar-pin {
          display: inline-block;
          > span {
            display: block;
            height: 16px;
            width: 16px;
            @include border-radius(50%);
            border: 2px solid #bfc2c6;
          }
        }
      }
      @include internalSubmenu;
      .layout-menu-container {
        overflow: auto;
      }
    }
  }
}

@media (max-width: 0px) {
  .layout-wrapper {
    .menu-wrapper {
      top: 62px;
      z-index: 1010;
      -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      transform: translate3d(-230px, 0px, 0px);
      @include transition(all $transitionDuration);
      .sidebar-logo {
        display: none;
      }
      .layout-menu-container {
        .layout-menu {
          max-width: 230px;
        }
      }
    }

    &.layout-mobile-active {
      overflow: hidden;
      height: 100vh;
      .menu-wrapper {
        transform: translate3d(0px, 0px, 0px);

        @include internalSubmenu;
        .layout-menu-container {
          overflow: auto;
        }
      }

      .layout-mask {
        display: block;
      }
    }
    .layout-topbar {
      .layout-topbar-wrapper {
        .layout-topbar-left {
          .menu-button {
            display: block;
          }
        }
      }
    }
  }
}

.layout-menuitem-img {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  object-fit: cover;
}
