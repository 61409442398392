@-webkit-keyframes modal-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes modal-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.modal-in {
  -webkit-animation-name: modal-in;
  animation-name: modal-in;
}
