.tag {
  padding: 3px 7px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 12.01px;
  font-weight: 700;
  line-height: 14.08px;
}
