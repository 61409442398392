@import "src/assets/theme/custom/theme-light.scss";

html {
  height: 100%;
  font-size: $fontSize;
}

body {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: 400;
  color: $textColor;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: $bodyBgColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.blocked-scroll {
    overflow: auto;
  }
}

a,
button {
  text-decoration: none;
  color: lighten($primaryColor, 10%);
  color: var(--primary-color);
}

.ajax-loader {
  font-size: 32px;
  color: $primaryColor;
  color: var(--primary-color);
}

.layout-main {
  @include flex();
  @include flex-direction-column();
  @include flex-justify-between();
  min-height: 100vh;
  @include transition(padding-left $transitionDuration);
}

.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: $animationDuration;
  animation-timing-function: $animationTimingFunction;
  animation-fill-mode: forwards;
}
