@import "src/assets/theme/custom/theme-light.scss";

.added-image {
  display: flex;
  gap: $gap;
  align-items: center;
  p {
    font-size: $fontSizeSmall;
    white-space: nowrap;
  }
  .download-icon {
    color: $shade1100;
  }
  .delete-icon {
    color: $red;
    font-size: $fontSize;
    cursor: pointer;
  }
}
