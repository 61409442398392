$primaryColor: #2170e7;
$primaryTextColor: #ffffff;

@import "../../sass/variables/layout/_layout_light";
@import "../../sass/layout/_layout";

.reminders-table-treatment-container {
  text-align: center;
  padding: 0px 11px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  height: 36px;
}
.col-wrapper {
  height: 100%;
  justify-content: start;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.coef-table-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  white-space: wrap;
  margin: 0;
}
.p-datatable .p-datatable-thead > tr > th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 11px;
  height: 35px;
}
.paginator-table-input {
  width: 50px;
  text-align: center;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.paginator-table-input-wrapper {
  display: flex;
  width: fit-content;
  align-items: center;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 0px;
}
.p-paginator .p-paginator-next {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: none;
  background: white;
  color: white;
  border-radius: 4px;
  height: 30px;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 4px;
  padding: 0px 15px;
  height: 30px;
}
.paginator-table-input-wrapper {
  margin-left: 40px !important;
  :first-child {
    margin-left: 10px !important;
  }
}
.p-dialog .p-dialog-header {
  border-radius: 0px;
}
.p-dialog-draggable .p-dialog-header {
  padding: 8px 20px;
  span {
    &::before {
      font-size: 12px !important;
    }
  }
  button {
    width: fit-content;
    height: fit-content;
    &:hover {
      border: none !important;
      box-shadow: none !important;
      background: #d3d3d3 !important;
    }
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.p-dialog .p-dialog-content {
  display: flex;
  flex-direction: column;
}
.client-response-popup-description-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 11px;
  .client-response-popup-description {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
  }
  .client-response-popup-description-buttons-wrapper {
    width: fit-content;
    display: flex;
    .client-response-popup-description-button {
      width: 24px;
      height: 24px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 11px;
      cursor: pointer;
      &:hover {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.client-response-popup-subdescription-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  .client-response-popup-subdescription-content-row {
    display: flex;
    width: fit-content;
    margin-right: 22px;
    align-items: center;
    img {
      margin-right: 9px;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .client-response-popup-subdescription-content-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      margin: 0;
    }
    b {
      font-style: normal;
      font-size: 11px;
      font-weight: 700;
    }
  }
  .client-response-popup-subdescription-content-column-margin-right {
    margin-left: 29px;
  }
}
.client-response-popup-footer-wrapper {
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding: 0px 6px;
  .client-response-popup-footer-buton {
    max-width: 211px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border: none;
    padding: 14px;
    margin-left: 3px;
    cursor: pointer;
    white-space: nowrap;
  }
  .client-response-popup-footer-input {
    width: 100%;
    border-radius: 0px;
    height: 45px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 14px;
  }
}
.client-response-popup-client-response-wrapper {
  width: 100%;
  display: flex;
  height: 272px;
  margin-top: 25px;
  .client-response-popup-client-response-container {
    width: 50%;
    position: relative;
    padding: 29px 17px 26px 17px;
    .client-response-popup-client-response-scrollable-div {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .client-response-popup-client-grade {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px !important;
      }
      .client-response-popup-client-no-grade {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px !important;
      }
      .client-response-popup-client-comment {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
      .client-response-popup-client-no-comment {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .client-response-popup-client-response-scrollable-div::-webkit-scrollbar {
      width: 0;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .client-response-popup-client-response-container-label {
      width: fit-content;
      border-radius: 4px;
      white-space: nowrap;
      padding: 5px 7px;
      position: absolute;
      top: -12px;
      left: 18px;
    }
    .client-response-popup-client-no-response-text {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.client-response-popup-client-comment-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 2px;
}
.p-contextmenu {
  width: fit-content !important;
  border-radius: 4px !important;
  padding: 10px 0px;
}
.p-contextmenu .p-menuitem-link {
  width: 100%;
  padding: 9px 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    box-shadow: none !important;
  }
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  margin-right: 11px !important;
  &::before {
    font-size: 18px;
  }
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.pacient-context-menu-line {
  padding: 0px !important;
  &:hover {
    background: none !important;
  }
  a {
    padding: 10px 0px 0px 0px !important;
    margin-bottom: 10px !important;
    background: none !important;
    &:hover {
      background: none !important;
    }
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: none !important;
  }
}
.pacient-context-menu-row-disable {
  a {
    cursor: not-allowed !important;
  }
}
.client-table-enabled-pacient-wrapper {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-right: 17px;
    cursor: pointer;
    text-decoration: underline;
  }
  p,
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
}

.login-logo {
  width: 120px !important;
  height: 100px !important;
}
.login-button {
  margin-top: 8px !important;
  width: 100% !important;
  max-width: 247px !important;
  border: none !important;
}
.login-input {
  width: 100% !important;
  max-width: 247px !important;
}

.exluded-client-popup-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 34px 40px 15px 40px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 27px;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-top: 8px;
    text-align: center;
  }
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 4px;
    margin-top: 50px;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}

.top-bar {
  width: 100%;
  height: 62px;
  background-color: #fff;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  display: flex;
  align-items: center;
  position: relative;
}

.logout-btn {
  position: absolute;
  right: 20px;
  background-color: transparent;
  border: none;
  padding: 10px;
  color: #293241;
  cursor: pointer;
  i {
    font-size: 20px;
    color: #293241;
  }
  &:hover {
    i {
      color: red;
    }
  }
}
