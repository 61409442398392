.layout-menu-light {
  $menuBgColor: #ffffff;
  $rootMenuItemTextColor: $primaryTextColor;
  $rootMenuItemHoverBgColor: var(--primary-lighter-color);

  $activeRootMenuItemTextColor: var(--primary-color);
  $menuItemIconColor: var(--primary-color);

  $submenuItemTextColor: rgba(41, 50, 65, 0.7);
  $submenuItemHoverBgColor: var(--primary-lighter-color);
  $activeSubmenuItemTextColor: rgba(41, 50, 65, 0.8);

  $slimActiveRootMenuItemBgColor: var(--primary-lighter-color);
  $slimActiveRootMenuItemTextColor: var(--primary-color);

  $menuShadow: 0px 1px 3px rgba(40, 40, 40, 0.25);

  @import "../_menu_theme";
}
