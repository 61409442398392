/* Add your customizations of the layout styles here */

.layout-wrapper {
  .layout-rightpanel {
    .rightpanel-wrapper {
      position: relative;
      height: 100%;

      .rightpanel-header {
        text-align: center;

        .profile {
          padding: 12px;
        }
      }

      .rightpanel-actions {
        padding: 12px 6px 36px;

        .actions {
          .action-buttons {
            .col-6,
            .md:col-4 {
              padding: 0.2em;
            }
          }
        }
      }
    }
  }
}
