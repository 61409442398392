:root {
  --white: #ffffff;
  --black: #000000;
  --blue: #1e0fec;
  --lightGreen: #cee105;
  --afterLightGreen: #c0d106;
  --darkGreen: #b0e0b0;
  --lightBlack: #1e202c;
  --darkBlue: #010b33;
  --gray: #84888e;
  --gray2: #6a6c74;
  --gray3: #d4d6d9;
  --gray4: #d9dbdd;
  --lightGray: #e9e9e9;
  --preLightGray: #fcfcfc;
  --lightRed: #f15c27;
  --red: rgb(241, 55, 31);
  --darkRed: rgb(190, 28, 6);
  --colorPaletteRedForeground: #e01e5a;
  --borderBlack: rgba(41, 50, 65, 0.25);
  --colorNeutralForeground: #69707a;
  --colorNeutralForeground2: #f6f7f7;
  --colorNeutralForeground3: #d3d3d3;
  --colorNeutralForeground4: #dddee1;
  --colorNeutralForeground5: #f6f6f6;
  --colorFiolForegroundLight: #f4e8f8;
  --colorNeutralBackgroundInverted: #000a35;
  --colorNeutralBackgroundInverted2: #293241;
  --colorNeutralBackgroundInverted3: #293241;
  --colorPaletteGreen: #256029;
  --colorPaletteGreenBackground: #c8e6c9;
  --colorPaletteYellowBackground: #ffda6d;
  --colorPaletteYellowBInverted: #f5c32e;
}