.fc {
  &.fc-theme-standard {
    .fc-highlight {
      background: $primaryLighterColor;
    }
  }
}

.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      border-radius: borderRadiusLarge;
    }
  }

  .p-accordion-content {
    border-bottom-right-radius: $borderRadiusMedium;
    border-bottom-left-radius: $borderRadiusMedium;
  }

  .p-accordion-tab {
    margin-bottom: $accordionSpacing;
    background-color: $accordionHeaderActiveBg;
    border-radius: $borderRadiusMedium;
    border: $accordionContentBorder;
    .p-accordion-content {
      border: none;
    }
    @if $accordionSpacing == 0 {
      .p-accordion-content {
        border-bottom-right-radius: $borderRadiusMedium;
        border-bottom-left-radius: $borderRadiusMedium;
        overflow: hidden;
      }
      &:first-child {
        .p-accordion-header {
          .p-accordion-header-link {
            border-top: none;
            border-right: none;
            border-left: none;
            border-top-right-radius: $borderRadiusMedium;
            border-top-left-radius: $borderRadiusMedium;
            border-bottom-right-radius: $borderRadiusMedium;
            border-bottom-left-radius: $borderRadiusMedium;
          }
        }
      }

      &:last-child {
        .p-accordion-header:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: $borderRadiusMedium;
            border-bottom-left-radius: $borderRadiusMedium;
          }

          .p-accordion-content {
            border-bottom-right-radius: $borderRadiusMedium;
            border-bottom-left-radius: $borderRadiusMedium;
          }
        }
      }
    }
  }
}
